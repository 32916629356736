document.addEventListener('DOMContentLoaded', function(){
    iniciarApp();
})
function iniciarApp() {
    navegacionFija();
    if (document.querySelector('.galeria-imagenes')) {
        crearGaleria();
    }
    scrollNav();
}


function navegacionFija(){
    const barra = document.querySelector('.header');
    const sobreFestival = document.querySelector('.sobre-festival');
    const body = document.querySelector('body');
    
    /**window.addEventListener('scroll', function(){
        if(sobreFestival.getBoundingClientRect().bottom < 0 ){
            barra.classList.add('fijo');
            body.classList.add('body-scroll')
        }else{
            barra.classList.remove('fijo');
            body.classList.remove('body-scroll');
        }       
    });**/
};


function scrollNav() {
    document.querySelectorAll(".navegacion-principal a").forEach(anchor => {
        anchor.addEventListener("click", function (event) {
            console.log("Enlace clicado:", anchor.getAttribute('href'));
            const href = anchor.getAttribute('href');

            // Verifica si el href comienza con '#' y si es un enlace de navegación interna
            if (href.startsWith("#")) {
                event.preventDefault(); // Previene el comportamiento predeterminado para enlaces internos
                const targetElement = document.querySelector(href);

                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: "smooth" });
                } else {
                    console.error("Elemento no encontrado para href:", href);
                }
            }
            // Si el href no comienza con '#', el navegador seguirá el enlace (como info.html)
        });
    });
}

 function crearGaleria(){
    const galeria = document.querySelector('.galeria-imagenes');
    
    for (let i = 1; i <= 12; i++) {
        
        const imagen = document.createElement('picture');
        imagen.innerHTML =`
            <source srcset="build/img/galeria${i}.avif" type="image/avif">
            <source srcset="build/img/galeria${i}.webp" type="image/webp">
            <img loading="lazy" width="200" heigth="300" src="build/img/galeria${i}.jpg"
            alt="imagen galeria">`;

            imagen.onclick = function() {
                mostrarImagen(i);
            }
        galeria.appendChild(imagen);        
        
    }
}

function mostrarImagen(id){
    const imagen = document.createElement('picture');
    imagen.innerHTML =`
        <source srcset="build/img/galeria${id}.avif" type="image/avif">
        <source srcset="build/img/galeria${id}.webp" type="image/webp">
        <img loading="lazy" width="200" heigth="300" src="build/img/galeria${id}.jpg"
        alt="imagen galeria">
        `;
    //crear overlay con la imagen
    const overlay = document.createElement('DIV');
    overlay.appendChild(imagen);
    overlay.classList.add('overlay'); 
    overlay.onclick = function(){
        const body = document.querySelector('body');
        body.classList.remove('fijar-body');
        overlay.remove();
    }
    
    // Boton para cerrar modal
    const cerrarModal = document.createElement('P');
    cerrarModal.textContent = 'X';
    cerrarModal.classList.add('btn-cerrar');
    cerrarModal.onclick = function(){
        const body = document.querySelector('body');
        body.classList.remove('fijar-body');
        overlay.remove();
    }

    overlay.appendChild(cerrarModal);

    //añadirlo al html
    const body = document.querySelector('body');
    body.appendChild(overlay);
    body.classList.add('fijar-body');
}